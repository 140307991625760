<template>
  <div class="dashboard">
    <a-row :gutter="16" class="statistic-row">
      <a-col
        v-for="item in sourceTypes"
        :key="item"
        :md="24"
        :lg="8"
        :xxl="4"
        style="margin-bottom: 16px"
      >
        <source-statistic-card :source-type="item"></source-statistic-card>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col
        :lg="24"
        :xl="16"
        style="
          margin-bottom: 16px;
          height: 395px;
          border-radius: 6px;
        "
      >
        <div class="current-alert-container">
          <div class="module-header" style="margin-bottom: 24px">当前告警</div>
          <div style="position: absolute; right: 20px; top: 17px">
            <span
              style="
                font-size: 14px;
                color: rgba(0, 0, 0, 0.5);
                line-height: 20px;
              "
            >
              告警等级：
            </span>
            <simple-select
              :options="severityOptions"
              @input="v => (currentSeverity = v.value)"
            ></simple-select>
            <span
              style="
                margin-left: 40px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.5);
                line-height: 20px;
              "
            >
              资源类型：
            </span>
            <simple-select
              :options="sourceTypeOptions"
              @input="v => (currentSourceType = v.value)"
            ></simple-select>
          </div>
          <alert-timeline
            :severity="currentSeverity"
            :sourceType="currentSourceType"
            @total="v => (currentAlertCount = v)"
            class="overflow-scroll-hidden"
            style="padding: 8px 19px 16px 16px; overflow: auto; height: 328px"
          ></alert-timeline>
        </div>
      </a-col>
      <a-col
        :sm="24"
        :lg="12"
        :xl="8"
        style="
          margin-bottom: 16px;
          height: 395px;
          border-radius: 6px;
        "
      >
        <div class="alert-trend-container">
          <div class="module-header" style="margin-bottom: 24px">
            近七日告警恢复趋势
          </div>
          <div style="position: absolute; right: 20px; top: 17px">
            <simple-select
              :options="severityOptions"
              @input="v => (alertTrendSeverity = v.value)"
            ></simple-select>
          </div>
          <a-spin :spinning="spinning['alertTrend']">
            <alert-trend-chart
              v-if="trendData.length"
              :chart-data="trendData"
              chart-id="alert-trend-chart"
              :height="320"
            ></alert-trend-chart>
            <div v-else style="height: 320px; position: relative; overflow: hidden;">
              <empty-component
                :body-style="{
                  height: '216px',
                  marginTop: '34px'
                }"
                :description-style="{
                  marginTop: '-28px'
                }"
              ></empty-component>
            </div>
          </a-spin>
        </div>
      </a-col>
      <a-col
        :sm="24"
        :lg="12"
        :xl="8"
        style="
          margin-bottom: 16px;
          height: 395px;
          border-radius: 6px;
        "
      >
        <div class="alert-source-top-container dashboard-alert-container">
          <div class="module-header" style="margin-bottom: 24px">
            近七日告警资源 TOP5
          </div>
          <div style="position: absolute; right: 20px; top: 17px">
            <simple-select
              :options="severityOptions"
              @input="v => (alertSourceSeverity = v.value)"
            ></simple-select>
            <simple-select
              :options="sourceTypeOptions"
              @input="v => (alertSourceType = v.value)"
              style="margin-left: 18px"
            ></simple-select>
          </div>
          <a-spin :spinning="spinning['alertSource']">
            <alert-info-top
              :detail="topOfSource"
              v-if="topOfSource.length"
              :isUrl="true"
              paramKey="source_id"
            ></alert-info-top>
            <div v-else style="height: 320px; position: relative; overflow: hidden;">
              <empty-component
                :body-style="{
                  height: '216px',
                  marginTop: '34px'
                }"
                :description-style="{
                  marginTop: '-28px'
                }"
              ></empty-component>
            </div>
          </a-spin>
        </div>
      </a-col>
      <a-col
        :sm="24"
        :lg="12"
        :xl="8"
        style="
          margin-bottom: 16px;
          height: 395px;
          border-radius: 6px;
        "
      >
        <div class="alert-info-top-container dashboard-alert-container">
          <div class="module-header" style="margin-bottom: 24px">
            近七日告警信息 TOP5
          </div>
          <div style="position: absolute; right: 20px; top: 17px">
            <simple-select
              :options="severityOptions"
              @input="v => (alertInfoSeverity = v.value)"
            ></simple-select>
          </div>
          <a-spin :spinning="spinning['alertInfo']">
            <alert-info-top
              :detail="topOfName"
              v-if="topOfName.length"
              :isUrl="true"
              paramKey="name"
            ></alert-info-top>
            <div v-else style="height: 320px; position: relative; overflow: hidden;">
              <empty-component
                :body-style="{
                  height: '216px',
                  marginTop: '34px'
                }"
                :description-style="{
                  marginTop: '-28px'
                }"
              ></empty-component>
            </div>
          </a-spin>
        </div>
      </a-col>
      <a-col
        :sm="24"
        :lg="12"
        :xl="8"
        style="
          margin-bottom: 16px;
          height: 395px;
          border-radius: 6px;
        "
      >
        <div class="alert-date-container dashboard-alert-container">
          <div class="module-header">系统统计</div>
          <div class="date-statistics">
            <a-col :span="12" style="text-align: center; margin-top: 18px">
              <div class="date-statistics-container">
                <p class="title">系统运行时长</p>
                <p style="font-size: 29px; color: #0a6dd9">
                  {{ getTime(systemCount.uptime) }}
                </p>
              </div>
            </a-col>
            <a-col :span="12" style="text-align: center; margin-top: 18px">
              <div class="date-statistics-container">
                <p class="title">监控指标总数</p>
                <p style="font-size: 29px; color: #faad14">
                  {{ systemCount.item }}
                </p>
              </div>
            </a-col>
            <a-col :span="12" style="text-align: center; margin-top: 18px">
              <div class="date-statistics-container">
                <p class="title">触发规则总数</p>
                <p style="font-size: 29px; color: #faad14">
                  {{ systemCount.trigger }}
                </p>
              </div>
            </a-col>
            <a-col :span="12" style="text-align: center; margin-top: 18px">
              <div class="date-statistics-container">
                <p class="title">近 7 日未恢复告警</p>
                <p style="font-size: 29px; color: #ff5b73">
                  {{ alertCount.unrecovered }}
                </p>
              </div>
            </a-col>
            <a-col :span="12" style="text-align: center; margin-top: 18px">
              <div class="date-statistics-container">
                <p class="title">近 7 日已恢复告警</p>
                <p style="font-size: 29px; color: #5fc367">
                  {{ alertCount.recovered }}
                </p>
              </div>
            </a-col>
            <a-col :span="12" style="text-align: center; margin-top: 18px">
              <div class="date-statistics-container">
                <p class="title">近 7 日告警总数</p>
                <p style="font-size: 29px; color: #ff5b73">
                  {{ alertCount.total }}
                </p>
              </div>
            </a-col>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import {
  grayColor,
  greenColor,
  redColor,
  alertSeverities,
  alertSourceTypes
} from '@/utils/const'
import {
  getAlertCount,
  getAlertTop,
  getAlertTrend
} from '@/api/alert'
import { getSystemStatus } from '@/api/system'
import { humanizeTime } from '@/utils'
import SimpleSelect from '@/components/select/SimpleSelect'
import AlertInfoTop from '@/components/top/AlertInfoTop'
import AlertTrendChart from './modules/AlertTrendChart'
import SourceStatisticCard from './modules/SourceStatisticCard'

export default {
  name: 'Dashboard',
  components: {
    AlertInfoTop,
    AlertTimeline: () => import('@/components/timeline/AlertTimeline'),
    AlertTrendChart,
    SimpleSelect,
    SourceStatisticCard,
    EmptyComponent: () => import('@/components/EmptyComponent.vue')
  },
  data () {
    return {
      sourceTypes: [
        'network_device',
        'storage',
        'server',
        'os',
        'middleware',
        'database'
      ],
      currentSeverity: 'all',
      currentSourceType: 'all',
      alertInfoSeverity: 'all',
      alertSourceSeverity: 'all',
      alertSourceType: 'all',
      alertTrendSeverity: 'all',
      alertCount: {
        recovered: 0,
        unrecovered: 0,
        total: 0
      },
      systemCount: {
        item: 0,
        uptime: 0,
        trigger: 0
      },
      currentAlertCount: 0,
      severityOptions: [
        { label: '全部', value: 'all' },
        ...alertSeverities.map(item => {
          return { label: this.$t(`alert_severity.${item}`), value: item }
        })
      ],
      sourceTypeOptions: [
        { label: '全部资源', value: 'all' },
        ...alertSourceTypes.map(item => {
          return { label: this.$t(`source_type.${item}`), value: item }
        })
      ],
      topOfSource: [],
      topOfName: [],
      trendData: [],
      spinning: {
        alertTrend: false,
        alertSource: false,
        alertInfo: false
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.spinning.alertTrend = true
      this.spinning.alertSource = true
      this.spinning.alertInfo = true
      getAlertCount({
        datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm')
      }).then(res => {
        const data = res.data
        let total = 0
        data.data.forEach(item => {
          total += item.value
          this.alertCount[item.name] = item.value
        })
        this.alertCount.total = total
      })

      getSystemStatus().then(res => {
        const data = res.data
        for (const key in this.systemCount) {
          if (Object.hasOwnProperty.call(this.systemCount, key)) {
            this.systemCount[key] = data[key]
          }
        }
      })

      getAlertTop({
        top_of: 'source',
        datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm'),
        limit: 5
      }).then(res => {
        this.topOfSource = res.data.data
      }).finally(() => { this.spinning.alertTrend = false })

      getAlertTop({
        top_of: 'name',
        datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm'),
        limit: 5
      }).then(res => {
        this.topOfName = res.data.data
      }).finally(() => { this.spinning.alertInfo = false })

      getAlertTrend({
        datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm'),
        trend_of: 'recovered'
      }).then(res => {
        const data = res.data.data
        this.formatterTrend(data)
      }).finally(() => { this.spinning.alertSource = false })
    },
    colors (v) {
      if (v === '在线') return greenColor
      else if (v === '离线') return redColor
      else return grayColor
    },
    getTime (v) {
      const length = humanizeTime(v).split(' ').length
      if (length >= 6) {
        return humanizeTime(v).split(' ').slice(0, 4).join('')
      }
      return humanizeTime(v).split(' ').join('')
    },
    formatterTrend (data) {
      const date = []
      data.forEach(item => {
        date.push(item.date)
      })
      for (const item of new Set(date)) {
        const split = item.split('-')
        const date = split[1] + '-' + split[2]
        const recovered = data.find(
          v => v.date === item && v.name === 'recovered'
        )
        const unrecovered = data.find(
          v => v.date === item && v.name === 'unrecovered'
        )
        const recoveredVal = recovered === undefined ? 0 : recovered.value
        const unrecoveredVal = unrecovered === undefined ? 0 : unrecovered.value
        const rate =
          parseInt((recoveredVal / (recoveredVal + unrecoveredVal)) * 10000) /
          100
        this.trendData.push(
          {
            date,
            type: 'total',
            alert: unrecoveredVal + recoveredVal,
            rate
          },
          {
            date,
            type: 'recovered',
            alert: recoveredVal,
            rate
          }
        )
      }
    }
  },
  watch: {
    alertInfoSeverity (v) {
      this.spinning.alertInfo = true
      if (v !== 'all') {
        getAlertTop({
          top_of: 'name',
          datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
          datetime_to: moment().format('YYYY-MM-DD HH:mm'),
          limit: 5,
          severity: v
        }).then(res => {
          this.topOfName = res.data.data
        }).finally(() => { this.spinning.alertInfo = false })
      } else {
        getAlertTop({
          top_of: 'name',
          datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
          datetime_to: moment().format('YYYY-MM-DD HH:mm'),
          limit: 5
        }).then(res => {
          this.topOfName = res.data.data
        }).finally(() => { this.spinning.alertInfo = false })
      }
    },
    alertSourceSeverity (v) {
      let params = {}
      this.spinning.alertSource = true
      if (v !== 'all' && this.alertSourceType !== 'all') {
        params = {
          severity: v,
          source_type: this.alertSourceType
        }
      } else if (v === 'all' && this.alertSourceType !== 'all') {
        params = {
          source_type: this.alertSourceType
        }
      } else if (this.alertSourceType === 'all' && v !== 'all') {
        params = {
          severity: v
        }
      }
      getAlertTop({
        top_of: 'source',
        datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm'),
        limit: 5,
        ...params
      }).then(res => {
        this.topOfSource = res.data.data
      }).finally(() => { this.spinning.alertSource = false })
    },
    alertSourceType (v) {
      let params = {}
      this.spinning.alertSource = true
      if (v !== 'all' && this.alertSourceSeverity !== 'all') {
        params = {
          severity: this.alertSourceSeverity,
          source_type: v
        }
      } else if (this.alertSourceSeverity === 'all' && v !== 'all') {
        params = {
          source_type: v
        }
      } else if (v === 'all' && this.alertSourceSeverity !== 'all') {
        params = {
          severity: this.alertSourceSeverity
        }
      }
      getAlertTop({
        top_of: 'source',
        datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm'),
        limit: 5,
        ...params
      }).then(res => {
        this.topOfSource = res.data.data
      }).finally(() => { this.spinning.alertSource = false })
    },
    alertTrendSeverity (v) {
      this.trendData = []
      this.spinning.alertTrend = true
      if (v !== 'all') {
        getAlertTrend({
          datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
          datetime_to: moment().format('YYYY-MM-DD HH:mm'),
          trend_of: 'recovered',
          severity: v
        }).then(res => {
          const data = res.data.data
          this.formatterTrend(data)
        }).finally(() => { this.spinning.alertTrend = false })
      } else {
        getAlertTrend({
          datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
          datetime_to: moment().format('YYYY-MM-DD HH:mm'),
          trend_of: 'recovered'
        }).then(res => {
          const data = res.data.data
          this.formatterTrend(data)
        }).finally(() => { this.spinning.alertTrend = false })
      }
    }
  }
}
</script>

<style lang="less">
.dashboard {
  .current-alert-container {
    background: #fff;
    border-radius: 6px;
    padding: 18px 16px;
    box-sizing: border-box;
    position: relative;
    height: 395px;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  }

  .alert-trend-container {
    background: #fff;
    border-radius: 6px;
    height: 395px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    padding: 18px 16px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  }

  .dashboard-alert-container {
    background: #fff;
    border-radius: 6px;
    height: 395px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    padding: 18px 16px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  }

  .alert-date-container {
    .date-statistics {
      .date-statistics-container {
        height: 90px;
        border-radius: 8px;
        padding-top: 14px;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 8px;
          left: 8px;
          bottom: 0;
          opacity: 0.06;
          border-radius: 8px;
          background: #096dd9;
        }
        p {
          margin: 0;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }

  .title {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.45);
  }
  .monitor-card .ant-col p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
