<template>
  <a-icon :component="component"></a-icon>
</template>

<script>
export default {
  name: 'AlertIcon',
  data () {
    return {
      component: {
        template: `
          <svg viewBox="0 0 1028 1024" width="1em" height="1em">
              <path d="M1019.733333 294.4c8.533333 17.066667 4.266667 38.4-12.8 46.933333L896 405.333333l-34.133333-59.733333 106.666666-64c8.533333-4.266667 17.066667-4.266667 25.6-4.266667 12.8 4.266667 21.333333 8.533333 25.6 17.066667zM4.266667 294.4c4.266667-8.533333 12.8-12.8 21.333333-17.066667 8.533333-4.266667 17.066667 0 25.6 4.266667l106.666667 64-34.133334 59.733333L17.066667 341.333333C0 332.8-4.266667 311.466667 4.266667 294.4z m213.333333-213.333333c8.533333-4.266667 17.066667-4.266667 25.6-4.266667 8.533333 4.266667 17.066667 8.533333 21.333333 17.066667l64 106.666666-59.733333 34.133334L204.8 128c-8.533333-17.066667-4.266667-38.4 12.8-46.933333zM512 0c21.333333 0 38.4 17.066667 38.4 34.133333v123.733334h-72.533333V34.133333c-4.266667-17.066667 12.8-34.133333 34.133333-34.133333z m290.133333 81.066667c17.066667 8.533333 21.333333 29.866667 12.8 46.933333l-64 106.666667-59.733333-34.133334 64-106.666666c8.533333-8.533333 12.8-12.8 21.333333-17.066667 8.533333 0 21.333333 0 25.6 4.266667z m-264.533333 315.733333L362.666667 682.666667h140.8l-34.133334 213.333333 174.933334-285.866667h-140.8l34.133333-213.333333zM230.4 947.2H157.866667v-341.333333C157.866667 413.866667 315.733333 256 512 256c196.266667 0 354.133333 157.866667 354.133333 349.866667v341.333333H230.4z" fill="currentColor" p-id="24350"></path>
              <path d="M38.4 947.2h951.466667c21.333333 0 38.4 17.066667 38.4 38.4s-17.066667 38.4-38.4 38.4H38.4c-21.333333 0-38.4-17.066667-38.4-38.4s17.066667-38.4 38.4-38.4" fill="currentColor" p-id="24351"></path>
            </svg>
        `
      }
    }
  }
}
</script>
