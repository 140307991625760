<template>
  <div class="source-statistics-card">
    <div :class="{ filter: filter }">
      <div class="source-container">
        <div>
          <img
            :src="require(`@/assets/icons/${sourceType}-dashboard.svg`)"
            style="margin: 2px 20px 1px 8px"
          />
          <div class="source-title-container">
            <p>{{ $t(`source_type.${sourceType}`) }}</p>
            <p>总数</p>
          </div>
        </div>
        <div class="source-count" @click="toSourceDetail">{{ sourceTotalCount }}</div>
      </div>

      <a-divider class="divider"></a-divider>

      <a-row :gutter="10" style="margin: 0">
        <a-col
          class="alert-container"
          :span="6"
          v-for="item in alertCount"
          :key="item.name"
        >
          <div class="content" @click="toAlert(item.name)">
            <p class="content-title">{{ $t(`alert_severity.${item.name}`) }}</p>
            <p class="content-count">{{ item.value }}</p>
            <alert-icon class="content-icon"></alert-icon>
          </div>
        </a-col>
      </a-row>
    </div>

    <div
      class="modal-container"
      v-if="sourceType === 'network_device' && visible"
    >
      <a-spin :spinning="spinning">
        <template v-if="!spinning && visible">
          <a style="float: right; margin-bottom: 26px" @click="close">
            <a-icon type="close"></a-icon>
          </a>
          <a-descriptions :column="2">
            <a-descriptions-item label="交换机">
              <a @click="$router.push({ name: 'Switchboard' })">{{ deviceList.switch }}</a>
            </a-descriptions-item>
            <a-descriptions-item label="安全设备">
              <a @click="$router.push({ name: 'Security' })">{{ deviceList.security }}</a>
            </a-descriptions-item>
            <a-descriptions-item label="AC-AP">
              <a @click="$router.push({ name: 'ACAPList' })">{{ deviceList.access_controller }}</a>
            </a-descriptions-item>
            <a-descriptions-item label="终端设备">
              <a @click="$router.push({ name: 'Terminal' })">{{ deviceList.terminal }}</a>
            </a-descriptions-item>
          </a-descriptions>
        </template>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { getDatabaseCount } from '@/api/database'
import { getMiddlewareCount } from '@/api/middleware'
import {
  getNetworkDeviceCount,
  getNetworkDeviceList
} from '@/api/network-device'
import { getOSCount } from '@/api/os'
import { getServerCount } from '@/api/server'
import { getStorageCount } from '@/api/storage'
import { getAlertCount } from '@/api/alert'
import { severityColor } from '@/utils'
import AlertIcon from '@/components/icon/AlertIcon'
import { securityType } from '@/utils/const.js'

export default {
  name: 'SourceStatisticCard',
  components: {
    AlertIcon
  },
  props: {
    sourceType: {
      type: String,
      required: true
    }
  },
  computed: {
    getSourceCountFunc () {
      switch (this.sourceType) {
        case 'network_device':
          return getNetworkDeviceCount
        case 'storage':
          return getStorageCount
        case 'server':
          return getServerCount
        case 'os':
          return getOSCount
        case 'middleware':
          return getMiddlewareCount
        case 'database':
          return getDatabaseCount
        default:
          return undefined
      }
    },
    sourceRouteName () {
      switch (this.sourceType) {
        // TODO: 完善网络设备跳转
        // case 'network_device':
        //   return 'Security'
        case 'storage':
          return 'Storage'
        case 'server':
          return 'Server'
        case 'middleware':
          return 'Middleware'
        case 'database':
          return 'Database'
        case 'os':
          return 'OS'
        default:
          return undefined
      }
    }
  },
  data () {
    return {
      sourceTotalCount: 0,
      severityColor: severityColor,
      alertCount: [
        { name: 'disaster', value: 0 },
        { name: 'high', value: 0 },
        { name: 'average', value: 0 },
        { name: 'warning', value: 0 }
      ],
      visible: false,
      deviceList: {},
      spinning: false,
      filter: false
    }
  },
  mounted () {
    getAlertCount({
      source_type: this.sourceType,
      count_of: 'severity',
      recovered: false
    }).then(res => {
      const data = res.data
      this.alertCount.map(item => {
        const index = data.data.findIndex(e => e.name === item.name)
        if (index !== -1) item.value = data.data[index].value
      })
    })
    if (this.getSourceCountFunc) {
      this.getSourceCountFunc().then(res => {
        const data = res.data.data
        let total = 0
        let normal = 0
        data.forEach(item => {
          total += item.value
          if (item.name === 'normal') {
            normal += item.value
          }
        })
        this.sourceNormalCount = normal
        this.sourceTotalCount = total
      })
    }
  },
  methods: {
    toSourceDetail () {
      if (this.sourceRouteName !== undefined) {
        const route = { name: this.sourceRouteName }
        this.$router.push(route)
      } else if (this.sourceType === 'network_device') {
        this.spinning = true
        this.visible = true
        this.filter = true
        this.fetchDeviceList()
      }
    },
    toAlert (severity) {
      this.$router.push({
        name: 'AlertHistory',
        params: {
          source_type: this.sourceType,
          severity,
          recovered: 'false'
        }
      })
    },
    fetchDeviceList () {
      const arr = ['switch', 'security', 'access_controller']
      Promise.all(
        arr.map(deviceType => {
          return new Promise(resolve => getNetworkDeviceList({
            device_type: deviceType === 'security' ? securityType : deviceType
          }).then(res => {
            this.deviceList[deviceType] = res.data.total
            resolve()
          }))
        })
      ).then(() => {
        this.spinning = false
      })
    },
    close () {
      this.visible = false
      this.spinning = false
      this.filter = false
    }
  }
}
</script>

<style lang="less">
.source-statistics-card {
  height: 160px;
  border-radius: 8px;
  background: #fff;
  padding: 18px 12px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

  .filter {
    filter: blur(2.5px);
  }

  .source-container {
    display: flex;
    justify-content: space-between;

    .source-title-container {
      float: right;
      p {
        margin: 0;
        font-family: PingFangSC-Regular, PingFang SC;

        &:nth-of-type(1) {
          height: 22px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 22px;
        }
        &:nth-of-type(2) {
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 17px;
        }
      }
    }

    .source-count {
      height: 36px;
      font-size: 25px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #096dd9;
      line-height: 36px;
      margin-right: 4px;
      cursor: pointer;
    }
  }

  .divider {
    background: rgba(216, 216, 216, 0.26);
    margin: 16px 0;
  }

  .alert-container {
    .content {
      height: 50px;
      border-radius: 3px;
      border: 1px solid black;
      padding: 5px 0;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      p {
        margin: 0;
        text-align: center;
        font-weight: 500;
      }

      .content-title {
        font-size: 12px;
        line-height: 16px;
        z-index: 1;
      }
      .content-count {
        font-size: 18px;
        line-height: 25px;
        z-index: 1;
      }
      .content-icon {
        position: absolute;
        font-size: 38px;
        bottom: -5px;
        right: -8px;
        opacity: 0.05;
        z-index: 0;
      }
    }
    &:nth-of-type(1) {
      .content {
        background: #fffafb;
        border-color: #ffc1ca;
        color: #ff5b73;
      }
    }
    &:nth-of-type(2) {
      .content {
        background: #fffcf7;
        border-color: #fde0a7;
        color: #faad14;
      }
    }
    &:nth-of-type(3) {
      .content {
        background: #f6fafe;
        border-color: #a2c8f1;
        color: #096dd9;
      }
    }
    &:nth-of-type(4) {
      .content {
        background: #f8fdff;
        border-color: #b2e9ff;
        color: #38d3fa;
      }
    }
  }

  .modal-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    right: 8px;
    border-radius: 8px;
    background: #e6f1ff74;
    padding: 8px 8px 8px 16px;
    text-align: center;

    .ant-descriptions-item-content {
      font-weight: 500;
    }
  }
}
</style>
