<template>
  <div :id="chartId"></div>
</template>

<script>
import { Chart } from '@antv/g2'

const chart = {}

export default {
  name: 'AlertTrendChart',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 240
    }
  },
  data () {
    return {
      data: []
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      height: this.height,
      appendPadding: [24, 12, 0, 12]
    })
    chart[this.chartId].axis('rate', {
      grid: null,
      label: false
    })
    chart[this.chartId].axis('alert', {
      title: false
    })
    chart[this.chartId].scale('rate', {
      min: 0,
      max: 100
    })
    chart[this.chartId].scale('alert', {
      min: 0,
      nice: true
    })
    chart[this.chartId].legend({
      custom: true,
      items: [
        {
          value: 'total',
          name: '告警数',
          marker: { symbol: 'square', style: { fill: '#ff5b73', r: 5 } }
        },
        {
          value: 'recovered',
          name: '恢复数',
          marker: { symbol: 'square', style: { fill: '#5fc367', r: 5 } }
        },
        {
          value: 'rate',
          name: '恢复率',
          marker: {
            symbol: 'hyphen',
            style: { stroke: '#faad14', r: 5, lineWidth: 3 }
          }
        }
      ]
    })
    chart[this.chartId].tooltip(false)

    chart[this.chartId].removeInteraction('legend-filter')
    chart[this.chartId].interaction('element-highlight-by-x')

    chart[this.chartId]
      .interval()
      .position('date*alert')
      .color('type', ['#ff5b73', '#5fc367'])
      .adjust([
        {
          type: 'dodge',
          marginRatio: 0
        }
      ])
      .label('type', {
        position: 'middle',
        content: (originData) => {
          return originData.alert
        }
      })

    chart[this.chartId]
      .line()
      .position('date*rate')
      .color('#faad14')
      .size(2)
    chart[this.chartId]
      .point()
      .position('date*rate')
      .color('#fdae6b')
      .size(4)
      .label('rate', {
        style: {
          fill: '#faad14',
          fontSize: 12
        },
        content: item => {
          return item.rate + '%'
        }
      })
    this.render()
  },
  methods: {
    render () {
      if (this.chartData.length === 0) {
        chart[this.chartId].changeVisible(false)
      } else {
        chart[this.chartId].changeData(this.chartData)
        chart[this.chartId].changeVisible(true)
      }
    }
  },
  watch: {
    chartData () {
      this.render()
    }
  }
}
</script>

<style></style>
